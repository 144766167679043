import React from 'react'
import './Button1.css'

const Button1 = (props:any) => {
  return (
    <div>
      <button className='theme-button' >
        {props.name}
      </button>
    </div>
  )
}

export default Button1
