import React, { useState, useEffect } from 'react';
import './Worldd.css';
import image8 from '../../images/World/Group 1707478298.png';
import blu from '../../images/Services/Ellipse 2.png';


const Worldd: React.FC = () => {
  // Define initial counts and their target values
  const initialCounts: Record<string, number> = {
    count1: 0,
    count2: 0,
    count3: 0,
  };
  const targetCounts: Record<string, number> = {
    count1: 100,
    count2: 176,
    count3: 1000,
  };


  const [counts, setCounts] = useState(initialCounts);

  console.log("counts" , counts.count1 , counts.count2 , counts.count3);
  
  const animateCounts = () => {
    const animationDuration = 1000; // Duration for the counting animation in milliseconds
    const animationSteps = 50; // Number of steps for the animation
    const stepDuration = animationDuration / animationSteps;

    Object.keys(targetCounts).forEach((countKey) => {
      const targetValue = targetCounts[countKey];
      const initialValue = counts[countKey];
      const stepValue = (targetValue - initialValue) / animationSteps;
      let currentCount = initialValue;
      let animationStartTime: number | null = null;

      const updateCount = (timestamp: number) => {
        if (!animationStartTime) {
          animationStartTime = timestamp;
        }

        const elapsed = timestamp - animationStartTime;
        const step = Math.min(Math.floor(elapsed / stepDuration), animationSteps);

        currentCount = initialValue + stepValue * step;

        setCounts((prevCounts) => ({
          ...prevCounts,
          [countKey]: step === animationSteps ? targetValue : Math.round(currentCount),
        }));

        if (step < animationSteps) {
          requestAnimationFrame(updateCount);
        }
      };

      requestAnimationFrame(updateCount);
    });
  };

  useEffect(() => {
    animateCounts();
  }, []);
 


  return (
    <div className="container-fluid worldd pt-3">
      <img className='blu' src={blu} alt="" />
      <div className="container worldd-container">
        <div className="row">
          {/* ======== left ======== */}
          <div className="col-lg-6 col-12 d-flex flex-column align-items-center justify-content-center">
            <h2>
              We Deliver Our <span className="green">Projects</span> Over All The{' '}
              <span className="green">World</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fuga fugiat placeat harum
              odit rem dolor quaerat, corrupti impedit commodi sit blanditiis voluptate, facere
              ducimus ipsa dicta autx?
            </p>
            <div className="counting  w-100">
              <div className="count">
                <h4 className="mb-0">{counts.count1}</h4>
                <p>lorem</p>
              </div>

              <div className="count">
                <h4 className="mb-0">{counts.count2}</h4>
                <p>lorem</p>
              </div>

              <div className="count">
                <h4 className="mb-0">{counts.count3}</h4>
                <p>lorem</p>
              </div>
            </div>
          </div>
          {/* ======== right ======== */}
          <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center">
            <img src={image8} width="100%" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Worldd;
