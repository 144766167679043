import React from "react";
import "./Helpp.css"
import image10 from "../../images/help/Rectangle 10.png";
import image11 from "../../images/help/Rectangle 11.png";
import image12 from "../../images/help/Rectangle 12.png";
import blu2 from "../../images/help/blue background.png";

const Helpp = () => {
  return (
    <div>
      <div className="container-fluid pt-5 mt-5 help">
        <img className="blu2" src={blu2} alt="" />
        <div className="container help-container">
          <div className="row">
            {/* ===left=== */}
            <div className="col-lg-6 left">
              <div className="d-flex justify-content-evenly align-items-center">
                <img className="image10" src={image10} alt="" />
                <img className="image11" src={image11} alt="" />
                <img className="image12" src={image12} alt="" />
              </div>
            </div>
            {/* ====right==== */}
            <div className="col-lg-6 right">
              <h2>See How We Can <span className="green">Help</span> You</h2>
              <h2><span className="green">Reach</span> Your <span className="green">Goals</span></h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <h4>WHAT IS YOUR <span className="green">INDUSTRY?</span></h4>
             <div className="d-flex justify-content-lg-end justify-content-center">
             <div className="line"></div>
             </div>
            </div>
            {/* ======== */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Helpp;
