import React from "react";
import "./Awardss.css";
import Slider from "react-slick";
import award1 from "../../images/Awards/award1.png";
import award2 from "../../images/Awards/award2.png";
import award3 from "../../images/Awards/award3.png";
import award4 from "../../images/Awards/award4.png";
import blu2 from "../../images/help/blue background.png";

const settings = {
    dots: false,             // Disable navigation dots
    arrows: true,           // Disable navigation arrows
    infinite: true,          // Allow infinite looping of slides
    speed: 500,             // Transition speed (1 second)
    slidesToShow: 4,         // Number of slides displayed at a time (default)
    slidesToScroll: 1,       // Number of slides scrolled per action
    autoplay: false,          // Enable autoplay
    autoplaySpeed: 500,      // Autoplay interval (0.5 seconds)
    easing: "ease-in-out",   
};


const Awardss = () => {
  return (
    <div className="container-fluid awards pt-5">
      <img className="blu2" src={blu2} alt="" />
      <div className="container awards-container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="mt-3">
              Our <span className="green">Awards</span>
            </h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>

          <div className="col-md-6 align-items-center" >
            <div className="awards-slider mt-5">
              <Slider className="awa" {...settings}>
                <center>
                <div className="award-img">
                <img src={award1} alt="" />
                </div>
                </center>

                <center>
                <div className="award-img">
                <img src={award2} alt="" />
                </div>
                </center>

                <center>
                <div className="award-img mt-3">
                <img src={award3} alt="" />
                </div>
                </center>

                <center>
                <div className="award-img">
                <img src={award1} alt="" />
                </div>
                </center>

                <center>
                <div className="award-img">
                <img src={award4} alt="" />
                </div>
                </center>

                
               
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awardss;
