import React from 'react'
import './Aboutme.css'
import Button1 from '../Button/Button1'
import aboutimg from '../../images/Aboutme/aboutme.png'

const Aboutme = () => {
  return (
    <div className='container-fluid aboutme mt-5 mb-5'>
        
        <div className="container aboutme-container">
            <div className="row">
                <div className="col-md-8 about-left mt-5">
                    <h2>About <span className='green'>Me</span></h2>
                    <h2 className='mt-4' style={{fontSize:40 , lineHeight:1 , width:'90%'}}>Lorem <span className='green'>Ipsum Freelance</span> Web Developer</h2>
                    <p className='mt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.</p>
                    <Button1 name="View All"/> 
                </div>

                <div className="col-md-4 about-right mt-5">
                   <div className="pt-5 pb-5">
                   <div className="about-img">
                   <img src={aboutimg} alt="" />
                   </div>
                   </div>
                   <div className="gre-back">
           
                    </div>
                </div>
            </div>
            <center><div className="line mt-5"></div></center>
        </div>
    </div>
  )
}

export default Aboutme
