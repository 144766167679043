import React from 'react'
import './Footerr.css'
import flogo from "../../images/logo/logo.png";
import facebook from '../../images/Footer/facebook (1).png'
import instagaram from '../../images/Footer/instagram.png'
import twitter from '../../images/Footer/twitter (1).png'


const Footerr = () => {
  return (
    <div className="container-fluid">
      <div className='container pt-5 footer'>
        <div className="row pt-5 pb-3">

            <div className="col-lg-4 col-md-6 col-6">
                  <img src={flogo} alt="" />
                  <ul className='mt-5 p-0'>
                    <li><a href=""><img src={facebook} alt="" /><p className='text-white'>Facebook</p></a></li>
                    <li><a href=""><img src={instagaram} alt="" /><p className='text-white'>Instagram</p></a></li>
                    <li><a href=""><img src={twitter} alt="" /><p className='text-white'>Twitter</p></a></li>
                  </ul>
            </div>

            <div className="col-lg-2 col-md-3 col-6">
                  <ul className='p-0'>
                    <li><a href="#"><p>resources</p></a></li>
                    <li><a href=""><p>help center</p></a></li>
                    <li><a href=""><p>status</p></a></li>
                    <li><a href=""><p>release notes</p></a></li>
                    <li><a href=""><p>community</p></a></li>
                    <li><a href=""><p>security</p></a></li>
                  </ul>
            </div>

            <div className="col-lg-2 col-md-3 col-6">
                  <ul className='p-0'>
                    <li><a href="#"><p>company</p></a></li>
                    <li><a href=""><p>careers</p></a></li>
                    <li><a href=""><p>about us</p></a></li>
                    <li><a href=""><p>investor</p></a></li>
                    <li><a href=""><p>accessibility</p></a></li>
                    <li><a href=""><p>sustainbility</p></a></li>
                  </ul>
            </div>

            <div className="col-lg-2 col-md-6 col-6">
                  <ul className='p-0'>
                    <li><a href="#"><p>get in touch</p></a></li>
                    <li><a href=""><p>sign up free</p></a></li>
                    <li><a href=""><p>sign in</p></a></li>
                    <li><a href=""><p>support</p></a></li>
                    <li><a href=""><p>contact us</p></a></li>
                  </ul>
            </div>


            <div className="col-lg-2 col-md-6">
                  <ul className='p-0'>
                    <li><a href="#"><p>legal</p></a></li>
                    <li><a href=""><p>privacy policy</p></a></li>
                    <li><a href=""><p>term of services</p></a></li>
                  </ul>
            </div>

        </div>
    </div>
    <div className="row lower-footer">
          <div className="col-12 d-flex align-items-center justify-content-center">
          @2023 vitesol. all right reserved
          </div>
    </div>
    </div>
  )
}

export default Footerr
