import React from "react";
import Slider, { Settings } from "react-slick";
import "./Testimoniall.css";
import per from "../../images/Testimonial/per.png";
import blu3 from "../../images/Testimonial/Ellipse 4.png";

const Testimoniall: React.FC = () => {
  const settings3 = {
    dots: true, // Disable navigation dots
    arrows: false, // Disable navigation arrows
    infinite: true, // Allow infinite looping of slides
    speed: 1000, // Transition speed (1 second)
    slidesToShow: 1, // Number of slides displayed at a time (default)
    slidesToScroll: 1, // Number of slides scrolled per action
    autoplay: false, // Enable autoplay
    autoplaySpeed: 4000, // Autoplay interval (0.5 seconds)
    easing: "ease-in-out",
  };

  return (
    <div className="container-fluid mt-5 mb-5 main-testi">
      <img className="blu3" src={blu3} alt="" />
      <div className="container testi-container">
        <center>
          <h2 style={{ color: "white" }}>
            <span className="green">Testim</span>onials
          </h2>
        </center>
        <center>
          <p style={{ color: "white", width:'80%' , marginBottom:'50px' }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
        </center>
        <Slider {...settings3}>
          <center>
            <div className="testimonial">
              <div className="row">
                <div className="col-6">
                  <div className="testi-card me-2">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                      <div className="testi-footer">
                        <div className="testi-ava">
                          <img src={per} alt="" />
                        </div>
                        <div className="testi-detil ms-2">
                          <h6 className="fw-bold mb-0">Russell Lee</h6>
                          <p className="text-secondary fot-p  mb-0">
                            Developer
                          </p>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>

                <div className="col-6">
                  <div className=" ms-2 testi-card">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                      <div className="testi-footer">
                        <div className="testi-ava">
                          <img src={per} alt="" />
                        </div>
                        <div className="testi-detil ms-2">
                          <h6 className="fw-bold mb-0">Russell Lee</h6>
                          <p className="text-secondary fot-p  mb-0">
                            Developer
                          </p>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <center>
                    <div className="green-cir"></div>{" "}
                  </center>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="testi-card me-2">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                      <div className="testi-footer">
                        <div className="testi-ava">
                          <img src={per} alt="" />
                        </div>
                        <div className="testi-detil ms-2">
                          <h6 className="fw-bold mb-0">Russell Lee</h6>
                          <p className="text-secondary fot-p  mb-0">
                            Developer
                          </p>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>

                <div className="col-6">
                  <div className="testi-card ms-2">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                      <div className="testi-footer">
                        <div className="testi-ava">
                          <img src={per} alt="" />
                        </div>
                        <div className="testi-detil ms-2">
                          <h6 className="fw-bold mb-0">Russell Lee</h6>
                          <p className="text-secondary fot-p  mb-0">
                            Developer
                          </p>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </center>
          {/* =========silder 1 end ======== */}
          <center>
            <div className="testimonial">
              <div className="row">
                <div className="col-6">
                  <div className="testi-card me-2">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                      <div className="testi-footer">
                        <div className="testi-ava">
                          <img src={per} alt="" />
                        </div>
                        <div className="testi-detil ms-2">
                          <h6 className="fw-bold mb-0">Russell Lee</h6>
                          <p className="text-secondary fot-p  mb-0">
                            Developer
                          </p>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>

                <div className="col-6">
                  <div className=" ms-2 testi-card">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                      <div className="testi-footer">
                        <div className="testi-ava">
                          <img src={per} alt="" />
                        </div>
                        <div className="testi-detil ms-2">
                          <h6 className="fw-bold mb-0">Russell Lee</h6>
                          <p className="text-secondary fot-p  mb-0">
                            Developer
                          </p>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <center>
                    <div className="green-cir"></div>{" "}
                  </center>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="testi-card me-2">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                      <div className="testi-footer">
                        <div className="testi-ava">
                          <img src={per} alt="" />
                        </div>
                        <div className="testi-detil ms-2">
                          <h6 className="fw-bold mb-0">Russell Lee</h6>
                          <p className="text-secondary fot-p  mb-0">
                            Developer
                          </p>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>

                <div className="col-6">
                  <div className="testi-card ms-2">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                      <div className="testi-footer">
                        <div className="testi-ava">
                          <img src={per} alt="" />
                        </div>
                        <div className="testi-detil ms-2">
                          <h6 className="fw-bold mb-0">Russell Lee</h6>
                          <p className="text-secondary fot-p  mb-0">
                            Developer
                          </p>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </center>
          {/* ========= slide 2 end======== */}
        </Slider>
      </div>
    </div>
  );
};

export default Testimoniall;
