import './Featuress.css'
import Slider from "react-slick";

import responsive from '../../images/Features/responsive.png'
import res1 from '../../images/Features/small_mockup_728x485_bizzyowl_4b9816c70b.png'
import hand from '../../images/Features/expand.png'
import hand1 from '../../images/Features/small_mockup_728x485_greenway_9e47ffee04.png'
import ex from '../../images/Features/hand.png'
import ex1 from '../../images/Features/small_puccini_ecf38dc138.png'
import hb from '../../images/Features/secure.png'
import hb1 from '../../images/Features/small_sitevibes_d0e4a7db0f.png'

const Featuress = () => {


    const settings = {
        dots: false,          
        arrows: true,          
        infinite: true,       
        speed: 1000,             
        slidesToShow: 4,         
        slidesToScroll: 1,   
        autoplay: false,       
        autoplaySpeed: 1000,   
        responsive: [
            {
                breakpoint: 1800,  
                settings: {
                    slidesToShow: 3, 
                }
            },
            {
              breakpoint: 1300,
              settings: {
                  slidesToShow: 2, 
              }
          },
          {
            breakpoint: 900, 
            settings: {
                slidesToShow: 1,
            }
        },
        ]
    };

  return (
    <div>
      <div className="container-fluid featuress pt-5 mt-5">

        <div className="container featuress-container">
          <div className="col-md-12">
            <h2 className="text-white">
              Our <span className="green">Features</span>
            </h2>
            <p className="text-white">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>

        <div className="second-slider  ps-5 pe-5">

        <Slider className='slider' {...settings}>

            <center>
            <div className="cover-card">
            <div className="sec-card ">
                <div className="left-s">
                <div className="gray-box">
                    <img src={responsive} alt="" />
                </div>
                <h6 >Responsive Design</h6>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem quae nesciunt quis officiis praesentium  </p>
                </div>
                <div className="right-s">
                    <img src={res1} alt="" />
                </div>
            </div>
            </div>
            </center>

            <center>
            <div className="cover-card">
            <div className="sec-card ">
                <div className="left-s">
                <div className="gray-box">
                    <img src={hand} alt="" />
                </div>
                <h6 >Responsive Design</h6>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem quae nesciunt quis officiis praesentium  </p>
                </div>
                <div className="right-s">
                    <img src={hand1} alt="" />
                </div>
            </div>
            </div>
            </center>

            <center>
            <div className="cover-card">
            <div className="sec-card ">
                <div className="left-s">
                <div className="gray-box">
                    <img src={ex} alt="" />
                </div>
                <h6 >Responsive Design</h6>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem quae nesciunt quis officiis praesentium  </p>
                </div>
                <div className="right-s">
                    <img src={ex1} alt="" />
                </div>
            </div>
            </div>
            </center>

            <center>
            <div className="cover-card">
            <div className="sec-card ">
                <div className="left-s">
                <div className="gray-box">
                    <img src={hb} alt="" />
                </div>
                <h6 >Responsive Design</h6>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem quae nesciunt quis officiis praesentium  </p>
                </div>
                <div className="right-s">
                    <img src={hb1} alt="" />
                </div>
            </div>
            </div>
            </center>

            <center>
            <div className="cover-card">
            <div className="sec-card ">
                <div className="left-s">
                <div className="gray-box">
                    <img src={responsive} alt="" />
                </div>
                <h6 >Responsive Design</h6>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem quae nesciunt quis officiis praesentium  </p>
                </div>
                <div className="right-s">
                    <img src={res1} alt="" />
                </div>
            </div>
            </div>
            </center>


        
        </Slider>

            
        </div>

      </div>
      <center><div className="line mb-5"></div></center>
    </div>
  );
};

export default Featuress;
