import React from "react";
import "./Servicess.css";
import image4 from "../../images/Services/2048px-GraphQL_Logo.png";
import image5 from "../../images/Services/devops-logo-CDF1353483-seeklogo.png";
import image6 from "../../images/Services/nodejs.png";
import image7 from "../../images/Services/physics.png";



const services = () => {
  return (
<div className="container-fluid services" >

  <div className="container services-container">

      <h4>OUR </h4>
      <h2 className="mb-0">which <span className="green">Services</span> We Will</h2>
      <h2><span className="green">Provide</span> For You</h2>
      <p className="mt-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam natus delectus vero voluptatum adipisci, facere rerum repudiandae necessitatibus. Maiores consequatur earum culpa placeat. Inventore itaque incidunt quis provident eius ipsum rerum eum sed nemo repudiandae.</p>
      
      <div className="row">
        
        <div className="col-6  col-lg-4">
          <div className="service">
          <div className="img-cover">
            <img src={image4} alt="" />
            </div>
          <h5>Twillio</h5>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem, assumenda?</p>
          </div>
        </div>

        <div className="col-6 col-lg-4">
          <div className="service">
          <div className="img-cover">
            <img src={image5} alt="" />
          </div>
          <h5>Shopify</h5>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem, assumenda?</p>
          </div>
        </div>

        <div className="col-6  col-lg-4">
          <div className="service">
          <div className="img-cover">
            <img src={image6} alt="" />
          </div>
          <h5>Node Js</h5>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem, assumenda?</p>
          </div>
        </div>

        <div className="col-6 col-lg-4">
          <div className="service">
          <div className="img-cover">
            <img src={image7} alt="" />
          </div>
          <h5>React Js</h5>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem, assumenda?</p>
          </div>
        </div>

        <div className="col-6 col-lg-4">
          <div className="service">
          <div className="img-cover">
            <img src={image4} alt="" />
          </div>
          <h5>Html</h5>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem, assumenda?</p>
          </div>
        </div>

        <div className="col-6 col-lg-4">
          <div className="service">
          <div className="img-cover">
            <img src={image5} alt="" />
          </div>
          <h5>Bootstrap</h5>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem, assumenda?</p>
          </div>
        </div>

      </div>

  </div>
  <center><div className="line mt-5"></div></center>
</div>
  );
};

export default services;
