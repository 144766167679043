import React from "react";
import "./Clientss.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import image13 from "../../images/slider1/Astra.png";
import image14 from "../../images/slider1/cocacola.png";
import image15 from "../../images/slider1/currency.png";
import image16 from "../../images/slider1/image.png";
import image17 from "../../images/slider1/nova.png";
import image18 from "../../images/slider1/p&.png";
import image19 from "../../images/slider1/philips.png";
import image20 from "../../images/slider1/royal.png";
import image21 from "../../images/slider1/samsung.png";
import image22 from "../../images/slider1/uni.png";

const Clientss = () => {

  const settings = {
    dots: false,             // Disable navigation dots
    arrows: false,           // Disable navigation arrows
    infinite: true,          // Allow infinite looping of slides
    speed: 1500,             // Transition speed (1 second)
    slidesToShow: 5,         // Number of slides displayed at a time (default)
    slidesToScroll: 1,       // Number of slides scrolled per action
    autoplay: true,          // Enable autoplay
    autoplaySpeed: 500,      // Autoplay interval (0.5 seconds)
    easing: "ease-in-out",
    responsive: [
        {
            breakpoint: 1200,  // Adjust the breakpoint as needed
            settings: {
                slidesToShow: 3,  // Number of slides to show on a medium screen
            }
        },
        {
          breakpoint: 786,  // Adjust the breakpoint as needed
          settings: {
              slidesToShow: 2,  // Number of slides to show on a medium screen
          }
      },
      {
        breakpoint: 500,  // Adjust the breakpoint as needed
        settings: {
            slidesToShow: 1,  // Number of slides to show on a medium screen
        }
    },
    ]
};

const settings2 = {
  dots: false,             // Disable navigation dots
  arrows: false,           // Disable navigation arrows
  infinite: true,          // Allow infinite looping of slides
  speed: 1500,             // Transition speed (1 second)
  slidesToShow: 5,         // Number of slides displayed at a time (default)
  slidesToScroll: 1,       // Number of slides scrolled per action
  autoplay: true,          // Enable autoplay
  autoplaySpeed: 500,      // Autoplay interval (0.5 seconds)
  easing: "ease-in-out",
  rtl: true,               // Enable right-to-left mode (left to right)
  
  responsive: [
      {
          breakpoint: 1200,
          settings: {
              slidesToShow: 3,
          }
      },
      {
          breakpoint: 786,
          settings: {
              slidesToShow: 2,
          }
      },
      {
          breakpoint: 500,
          settings: {
              slidesToShow: 1,
          }
      }
  ]
};

  return (
    <div className="container-fluid Clients pt-5 pb-5">
      <div className="container">
        <h2>Our Clients</h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
      </div>
      <div className="carsoul mt-5">
        <Slider {...settings}>
          <div className="sid-img">
            <img src={image13} alt="" />
          </div>
          <div className="sid-img">
            <img src={image14} alt="" />
          </div>
          <div className="sid-img">
            <img src={image15} alt="" />
          </div>
          <div className="sid-img">
            <img src={image16} alt="" />
          </div>
          <div className="sid-img">
            <img src={image17} alt="" />
          </div>
          <div className="sid-img">
            <img src={image18} alt="" />
          </div>
        </Slider>
      </div>

      <div className="carsoul mt-5">
        <Slider {...settings2}>
          <div className="sid-img">
            <img src={image19} alt="" />
          </div>
          <div className="sid-img">
            <img src={image20} alt="" />
          </div>
          <div className="sid-img">
            <img src={image21} alt="" />
          </div>
          <div className="sid-img">
            <img src={image14} alt="" />
          </div>
          <div className="sid-img">
            <img src={image17} alt="" />
          </div>
          <div className="sid-img">
            <img src={image22} alt="" />
          </div>
        </Slider>
      </div>

    </div>
  );
};

export default Clientss;
