import React from "react";
import "./Contactt.css";
import arroww from "../../images/Contact/Group 57.png";
import Button1 from "../Button/Button1";

const Contactt = () => {
  return (
    <div className="container-fluid contact mt-5">
      <div className="container contact-container pt-5">
        <center>
          <h2>
            Ready To <span className="green">Collaborate?</span>
          </h2>
          <h4>Drop Us A Line</h4>
          <img src={arroww} alt="" />
        </center>

        <div className="row  mb-5">

          <div className="col-lg-6 d-flex align-items-center mt-5">
            <div className="contact-left">
              <input className="input" type="text" placeholder="Full Name*" />

              <div className="row">
                <div className="col-6">
                  <input
                    className="input"
                    type="text"
                    placeholder="Email Address*"
                  />
                </div>
                <div className="col-6">
                  <input
                    className="input"
                    type="text"
                    placeholder="Phone Number*"
                  />
                </div>
              </div>

              <input
                className="input"
                type="text"
                placeholder="Tell Us About Your Project*"
              />

              <div className=" d-flex justify-content-between align-items-end">
                <div className="check">
                  <div className="form-check mt-4">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      defaultValue=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      I Accept Privacy Policy
                    </label>
                  </div>
                  <div className="form-check mt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="flexCheckChecked"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Send Me NDA
                    </label>
                  </div>
                </div>
                <Button1 name="Send"/>
              </div>


            </div>
          </div>

          <div className="col-lg-6 d-flex align-items-center mt-5">
              <div className="contact-card">
                <center><h2>What <span style={{color:'#5942B7'}}>Happend</span> Next</h2></center>
                  <div className="li mt-4">
                    <span className="li-no">
                      1
                    </span>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita, distinctio!</p>
                  </div>

                  <div className="li">
                    <span className="li-no">
                      2
                    </span>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, incidunt. Cum iste aspernatur repellendus.</p>
                  </div>

                  <div className="li">
                    <span className="li-no">
                      3
                    </span>
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore amet natus laudantium molestias at in. Odio, harum veritatis?</p>
                  </div>

                  <div className="li">
                    <div className="li-no">
                      4
                    </div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita, distinctio!</p>
                  </div>

              </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Contactt;
