import React, { useState } from "react";
import logo from "../../images/logo/logo.png";
import "./Navbarr.css";
import image3 from "../../images/Section1/Ellipse 1.png"
import Button1 from "../Button/Button1";
import Particals from "../../Particals/Particals";


const Navbarr = () => {
    const [activeItem, setActiveItem] = useState<string>('');

    const handleItemClick = (itemName:string) => {
        setActiveItem(itemName);
    };
  return (
    <>
    <Particals />
    <div className="container-fluid">
        
      <div className="container">
      
      <img className="image3" src={image3} alt="" />
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
          <img src={logo} alt="" />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
           <i className="bi bi-list"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto me-auto mb-2 mb-lg-0">
              <li className={`nav-item ${activeItem === 'Home' ? 'active' : ''}`}
             onClick={() => handleItemClick("Home")}>
                Home
                <div className="dot"></div>
                </li>
                <li className={`nav-item ${activeItem === 'About' ? 'active' : ''}`}
             onClick={() => handleItemClick("About")}>
                About
                <div className="dot"></div>
                </li>
                <li className={`nav-item ${activeItem === 'Features' ? 'active' : ''}`}
             onClick={() => handleItemClick("Features")}>
                Features
                <div className="dot"></div>
                </li>
                <li className={`nav-item ${activeItem === 'Portfolio' ? 'active' : ''}`}
             onClick={() => handleItemClick("Portfolio")}>
                Portfolio
                <div className="dot"></div>
                </li>
                <li className={`nav-item ${activeItem === 'Blog' ? 'active' : ''}`}
             onClick={() => handleItemClick("Blog")}>
                Blog <div className="dot"></div>
                </li>
                <li className={`nav-item ${activeItem === 'Contact' ? 'active' : ''}`}
             onClick={() => handleItemClick("Contact")}>
                Contact <div className="dot"></div>
                </li>
            </ul>

              <Button1 name="Start Your Project" />
            
          </div>
        </div>
      </nav>
      </div>
    </div>
    </>
  );
};

export default Navbarr;
