import React from 'react';
import './App.css';
import Navbarr from './sections/Navbarr/Navbarr';
import Section1 from './sections/Section1/Section1';
import Servicess from './sections/Section2/servicess';
import Worldd from './sections/Worldd/Worldd';
import Helpp from './sections/Helpp/Helpp';
import Clientss from './sections/Clientss/Clientss';
import Featuress from './sections/Featuress/Featuress';
import Testimoniall from './sections/Testimoniall/Testimoniall';
import Aboutme from './sections/Aboutme/Aboutme';
import Awardss from './sections/Awardss/Awardss';
import Contactt from './sections/Contactt/Contactt';
import Footerr from './sections/Footer/Footerr';

function App() {
  return (
    <div className="App">  
      <Navbarr />
      <Section1 />
      <Servicess />
      <Worldd />
      <Helpp />
      <Clientss />
      <Featuress />
      <Testimoniall />
      <Aboutme />
      <Awardss />
      <Contactt />
      <Footerr />
    </div>
  );
}

export default App;
