
import animate from "../../images/Section1/animate.gif";
import "./Section1.css";
import Button1 from "../Button/Button1";
// import Particals from "../../Particals/Particals";

const Section1 = () => {

  

  return (
    <>
      <div className="container-fluid p-0 section1 ">
        {/* <Particals /> */}
        <div className="container section1-container">
          <div className="mt-5 mb-5">
            <div className="row">
              {/* =======left side ======== */}
              <div className="col-lg-6 d-flex align-items-center">
                <div className="left">
                  <h1>BETTER SOLUTION WITH </h1>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                  <Button1 name="Get Started" />
                </div>
              </div>
              {/* ====== right side==== */}
              <div className="col-lg-6">
                <div className="right d-flex">
                
                  <img src={animate} alt="animate" width="100%" />
                </div>
              </div>
              {/* ====== right side end==== */}

              <center>
                {" "}
                <div className="line mt-5"></div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1;
